<template>
  <vx-card>
    <div class="page-content page-container" id="page-content">
      <div class="">
        <div class="row container d-flex justify-content-center">
          <div class="col-md-8">
            <div class="card card-bordered">
              <div class="card-header row ">
                <h4 class="card-title dana-font col-sm-6">
                  <strong class="font-weight-bold text-primary"
                    >خدمات :
                  </strong>
                  <span class="">{{ serviceData.category }}</span>
                </h4>

                <span class=" bg-success text-white px-4 py-2 rounded-pill">
                  {{ serviceData.status_string }}
                </span>
              </div>
              <div
                class="ps-container ps-theme-default ps-active-y"
                id="chat-content"
              >
                <div class="media media-chat">
                  <div>
                    <div
                      class="media-body"
                      v-html="serviceData.description"
                    ></div>

                    <p class="meta">
                      <time>{{ serviceData.created_at }}</time>
                    </p>
                  </div>
                </div>
                <div class="media media-meta-day">
                  پاسخ تیم {{ serviceData.category }}
                </div>
                <div v-if="serviceData.admin_description">
                  <div class="media media-chat media-chat-reverse">
                    <div class="media-body">
                      <div
                        class=""
                        v-html="serviceData.admin_description"
                      ></div>

                      <!-- <p class="meta"><time datetime="2018">00:06</time></p> -->
                    </div>
                  </div>
                  <!-- <div class="media media-chat">
                    <div>
                      <div
                        class="media-body"
                        
                      >
                       انجام موارد درخواست شده ام را تایید میکنم و تمام توضیحات و موارد لازم را از سمت هایو دریافت کرده ام
                      </div>

                      <p class="meta">
                        <time>{{ serviceData.created_at }}</time>
                      </p>
                    </div>
                  </div> -->
                </div>
                <div v-if="serviceData.status !== 6">
                  <service-pay-details
                    :key="serviceData.id"
                    @confirm="confirmJob"
                    @failure="failureJob"
                    @accept="accept"
                    @deny="deny"
                    :payDetails="serviceData"
                    class="px-5"
                  ></service-pay-details>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import ServicePayDetails from './components/ServicePayDetails.vue'

export default {
  components: {
    ServicePayDetails
  },
  data () {
    return {
      serviceData: ''
    }
  },
  methods: {
    getDetails () {
      this.$http
        .get(`/service/${this.$route.params.id}`)
        .then(result => {
          this.serviceData = result.data.params
        })
        .catch(err => {
          console.log(err)
        })
    },
    accept () {
      this.$http
        .post('/service/pay', { service_id: this.$route.params.id })
        .then(result => {
          this.$vs.notify({ text: result.data.message, color: 'success' })
          this.getDetails()
        })
        .catch(err => {
          this.$vs.notify({ text: err.response.data.message, color: 'danger' })
        })
    },
    deny () {
      this.$http
        .post('/service/no/payment', { service_id: this.$route.params.id })
        .then(result => {
          this.$vs.notify({ text: result.data.message, color: 'success' })
          this.getDetails()
        })
        .catch(err => {
          this.$vs.notify({ text: err.response.data.message, color: 'danger' })
        })
    },
    confirmJob () {
      this.$http
        .post('/service/confirm/work', { service_id: this.$route.params.id })
        .then(result => {
          this.$vs.notify({ text: result.data.message, color: 'success' })
          this.getDetails()
        })
        .catch(err => {
          this.$vs.notify({ text: err.response.data.message, color: 'danger' })
        })
    },
    failureJob () {
      this.$http
        .post('/service/failure/work', { service_id: this.$route.params.id })
        .then(result => {
          this.$vs.notify({ text: result.data.message, color: 'success' })
          this.getDetails()
        })
        .catch(err => {
          this.$vs.notify({ text: err.response.data.message, color: 'danger' })
        })
    }
  },
  created () {
    this.getDetails()
  }
}
</script>

<style scoped>
.card-bordered {
  border: 1px solid #ebebeb;
}

.card {
  border: 0;
  border-radius: 0px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.padding {
  padding: 3rem !important;
}

body {
  background-color: #f9f9fa;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
  /* background: #48b0f7; */
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 15px 20px;
  background-color: transparent;
  border-bottom: 1px solid rgba(77, 82, 89, 0.07);
}

.card-header .card-title {
  padding: 0;
  border: none;
}

h4.card-title {
  font-size: 17px;
}

.card-header > *:last-child {
  margin-right: 0;
}

.card-header > * {
  margin-left: 8px;
  margin-right: 8px;
}

.btn-secondary {
  color: #4d5259 !important;
  background-color: #e4e7ea;
  border-color: #e4e7ea;
  color: #fff;
}

.btn-xs {
  font-size: 11px;
  padding: 2px 8px;
  line-height: 18px;
}

.btn-xs:hover {
  color: #fff !important;
}

.card-title {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(77, 82, 89, 0.07);
}

.ps-container {
  position: relative;
}

.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

.media-chat {
  padding-right: 64px;
  margin-bottom: 0;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.media .avatar {
  flex-shrink: 0;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  background-color: #f5f6f7;
  color: #8b95a5;
  text-transform: uppercase;
}

.media-chat .media-body {
  -webkit-box-flex: initial;
  flex: initial;
  display: table;
}

.media-body {
  /* width: 200px; */
  min-width: 0;
}

.media-chat .media-body p {
  position: relative;
  padding: 6px 8px;
  margin: 4px 0;
  background-color: #f5f6f7;
  border-radius: 3px;
  font-weight: 100;
  color: #9b9b9b;
}

.media > * {
  margin: 0 8px;
}

.media-chat .media-body p.meta {
  background-color: transparent !important;
  padding: 0;
  opacity: 0.8;
}

.media-meta-day {
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 0;
  color: #8b95a5;
  opacity: 0.8;
  font-weight: 400;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.media-meta-day::before {
  margin-right: 16px;
}

.media-meta-day::before,
.media-meta-day::after {
  content: "";
  -webkit-box-flex: 1;
  flex: 1 1;
  border-top: 1px solid #ebebeb;
}

.media-meta-day::after {
  content: "";
  -webkit-box-flex: 1;
  flex: 1 1;
  border-top: 1px solid #ebebeb;
}

.media-meta-day::after {
  margin-left: 16px;
}

.media-chat.media-chat-reverse {
  padding-right: 12px;
  padding-left: 64px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.media-chat {
  padding-right: 64px;
  margin-bottom: 0;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.media-chat.media-chat-reverse .media-body div {
  float: right;
  clear: right;
  background-color: #48b0f7;
  color: #fff;
}

.media-chat .media-body div {
  position: relative;
  padding: 6px 8px;
  margin: 4px 0;
  background-color: #cacaca;
  border-radius: 3px;
}

.border-light {
  border-color: #f1f2f3 !important;
}

.bt-1 {
  border-top: 1px solid #ebebeb !important;
}

.publisher {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 12px 20px;
  background-color: #f9fafb;
}

.publisher > *:first-child {
  margin-left: 0;
}

.publisher > * {
  margin: 0 8px;
}

.publisher-input {
  -webkit-box-flex: 1;
  flex-grow: 1;
  border: none;
  outline: none !important;
  background-color: transparent;
}

button,
input,
optgroup,
select,
textarea {
  font-family: Roboto, sans-serif;
  font-weight: 300;
}

.publisher-btn {
  background-color: transparent;
  border: none;
  color: #8b95a5;
  font-size: 16px;
  cursor: pointer;
  overflow: -moz-hidden-unscrollable;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

.file-group {
  position: relative;
  overflow: hidden;
}

.publisher-btn {
  background-color: transparent;
  border: none;
  color: #cac7c7;
  font-size: 16px;
  cursor: pointer;
  overflow: -moz-hidden-unscrollable;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

.file-group input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 20px;
}

.text-info {
  color: #48b0f7 !important;
}
</style>
