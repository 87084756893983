<template>
  <div>
    <div class="divTable blueTable">
      <div class="divTableHeading">
        <div class="divTableRow">
          <div class="divTableHead">خدمات</div>
          <div class="divTableHead">مبلغ</div>
          <div class="divTableHead">مالیات</div>
          <div class="divTableHead">مبلغ نهایی</div>
        </div>
      </div>
      <div class="divTableBody">
        <div class="divTableRow">
          <div class="divTableCell">{{payDetails.category}}</div>
          <div class="divTableCell">{{payDetails.amount}}</div>
          <div class="divTableCell">۹٪</div>
          <div class="divTableCell">{{payDetails.final_amount}}</div>
        </div>
      </div>
    </div>
    <div v-if="payDetails.status == 2">
      <vs-checkbox icon="attach_money" v-model="agree" class="mt-2" >توضیحات و قیمت ارائه شده برای انجام درخواستم را قبول میکنم، لطفا کار را شروع کنید</vs-checkbox>
      <b-button @click="$emit('accept')" :disabled="!agree" variant="success" class="float-right my-2 mx-2 px-12"> قبول دارم </b-button>
      <b-button @click="$emit('deny')" variant="light" class="float-right my-2">قبول ندارم</b-button> 
    </div>  
     <!--approve  -->
    <b-card v-if="payDetails.status === 4" class="mt-12 bg-grey-100">
      <p> درخواست شما انجام شد، لطفا بررسی نمایید و درصورت تایید کار این پیام را تایید کنید تا وضعیت درخواست شما به تکمیل شده تغییر کند، در غیراینصورت اگر همچنان درخواست شما نیاز به کار دارد بر روی دکمه عدم تایید کلیک کنید. همچنین دقت داشته باشید که وضعیت درخواست شما درصورت عدم پاسخگویی بصورت خودکار بعد از 3 روز به تایید شده تغییر میکند و درخواست بسته می شود. </p>
      <b-button @click="$emit('confirm')"  variant="primary" class="float-right  mx-2 px-12"> تایید </b-button>
      <b-button @click="$emit('failure')" variant="danger" class="float-right ">عدم تایید</b-button> 
    </b-card>
    <!-- <b-card v-if="payDetails.status === 5" class="mt-12 bg-grey-100">
      <p> این تسک با موفقیت توسط  </p>
      
    </b-card> -->
  </div>
</template>

<script>

export default {
  props: ['payDetails'],
 
  data () {
    return {
      agree : false
    }
  },
  created () {
    // console.log(this.payDetails)
  }
}
</script>

<style scoped>
div.blueTable {
  border: 1px solid #1C6EA4;
  background-color: #EEEEEE;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.divTable.blueTable .divTableCell, .divTable.blueTable .divTableHead {
  border: 1px solid #AAAAAA;
  padding: 3px 2px;
}
.divTable.blueTable .divTableBody .divTableCell {
  font-size: 13px;
}
.divTable.blueTable .divTableRow:nth-child(even) {
  background: #D0E4F5;
}
.divTable.blueTable .divTableHeading {
  background: #a41c5b;
  /* background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%); */
  /* background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%); */
  /* background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1C6EA4 100%); */
  border-bottom: 2px solid #444444;
}
.divTable.blueTable .divTableHeading .divTableHead {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  border-left: 2px solid #D0E4F5;
}
.divTable.blueTable .divTableHeading .divTableHead:first-child {
  border-left: none;
}

.blueTable .tableFootStyle {
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background: #D0E4F5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  border-top: 2px solid #444444;
}
.blueTable .tableFootStyle {
  font-size: 14px;
}
.blueTable .tableFootStyle .links {
    text-align: right;
}
.blueTable .tableFootStyle .links a{
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 5px;
}
.blueTable.outerTableFooter {
  border-top: none;
}
.blueTable.outerTableFooter .tableFootStyle {
  padding: 3px 5px; 
}
/* DivTable.com */
.divTable{ display: table; }
.divTableRow { display: table-row; }
.divTableHeading { display: table-header-group;}
.divTableCell, .divTableHead { display: table-cell;}
.divTableHeading { display: table-header-group;}
.divTableFoot { display: table-footer-group;}
.divTableBody { display: table-row-group;}
</style>